import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100dvh;
  display: flex;
`;

export const LogoBar = styled.img`
  width: 159px;
  display: block;
`;

export const Header = styled.header`
  width: 100%;
  height: 84px;
  /* margin-bottom: 3rem; */

  border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
`;

export const WrapperHeaderContent = styled.div`
  ${({ theme: { screen } }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    padding: 0 16px;
    max-width: ${screen["3xl"]};

    > h2 {
      color: #1d1d1b;
      text-align: center;
      font-family: "Noto Sans";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px; /* 100% */
    }
  `}
`;

export const Main = styled.main`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  overflow-x: auto;
  flex: 1;

  ${({ theme: { screen } }) => css`
    padding: 0 16px;
    width: 100%;
    max-width: ${screen["3xl"]};

    @media (min-width: ${screen.lg}) {
      padding: 3rem 20px 0;
    }
  `}
`;
