import { useEffect, useMemo, useState } from "react";
import { cleanStorage } from "../../../utils/cleanStorage";
import { useNavigate } from "react-router-dom";
import { useSessionStorage } from "../../../hooks/useSessionStorage";
import { useContextSite } from "../../../context/Context";

export const useMenu = () => {
  const [usuario] = useSessionStorage("Usuario");
  const firstName = usuario?.nome?.split(" ")[0];
  const { setIsLoad, setTokenContext } = useContextSite();
  const navigate = useNavigate();

  const initialIsOpen = useMemo(() => {
    const storedState = sessionStorage.getItem("menuIsOpen");
    return storedState !== null ? JSON.parse(storedState) : true;
  }, []);

  const [isOpen, setIsOpen] = useState(initialIsOpen);

  useEffect(() => {
    sessionStorage.setItem("menuIsOpen", JSON.stringify(isOpen));
  }, [isOpen]);

  function handleLogout() {
    setIsLoad(true);

    cleanStorage();
    setTokenContext("");

    setTimeout(() => {
      navigate("/login");
      setIsLoad(false);
    }, 1000);
  }

  return { isOpen, setIsOpen, handleLogout, firstName };
};
