import React from "react";
import { LayoutTemplate } from "../LayoutTemplate";
import * as S from "./styles";
import { SubTitle } from "../../Atoms/SubTitle";
import { LineChart } from "@mui/x-charts";
import { ILineChartprops, useDataTendencia } from "./useData";

const TrendChart = ({
  title,
  chartData,
}: {
  title: string;
  chartData: ILineChartprops;
}) => (
  <>
    <SubTitle>{title}</SubTitle>
    <S.Card>
      <LineChart
        margin={{ bottom: 70 }}
        height={300}
        width={760}
        xAxis={[{ scaleType: "band", data: chartData?.xAxis || [] }]}
        series={chartData?.series || []}
        slotProps={{
          legend: { hidden: true },
        }}
      />
    </S.Card>
  </>
);

export const TendenciesTemplate = () => {
  const {
    data,
    tendenciasGeral,
    tendenciasTipoServico,
    tendenciasUnidade,
    tendenciasAtendimento,
    tendenciasFaturamento,
  } = useDataTendencia();

  return (
    <LayoutTemplate title="Dashboard de Tendência">
      <S.Container>
        <TrendChart title="Tendência Geral" chartData={tendenciasGeral} />
        <TrendChart
          title="Tendência por Tipo de Serviço"
          chartData={tendenciasTipoServico}
        />
        <TrendChart
          title="Tendência por Tipo de Atendimento"
          chartData={tendenciasAtendimento}
        />
        <TrendChart
          title="Tendência por Unidade"
          chartData={tendenciasUnidade}
        />
        <TrendChart
          title="Tendência de Faturamento"
          chartData={tendenciasFaturamento}
        />
      </S.Container>

      <S.TableTitle>Desempenho em Relação a Meta</S.TableTitle>

      <S.Table>
        <S.TableHead>
          <tr>
            <td>Empresa</td>
            <td>Meta</td>
            <td>Realizado</td>
            <td>Média/dia</td>
            <td>Necessidade/dia</td>
            <td>Projeção</td>
          </tr>
        </S.TableHead>
        <S.TableBody>
          {data?.unidades?.length > 0 &&
            data?.unidades?.map((empresa) => (
              <tr key={Math.random()}>
                <td>{`${empresa?.cidade} - ${empresa.uf}`}</td>
                <td>{empresa?.metaQtd}</td>
                <td>{empresa?.qtdTotal}</td>
                <td>{empresa?.qtdMedia}</td>
                <td>{empresa?.qtdMediaNecessaria}</td>
                <td>{empresa?.qtdMediaProjecao}</td>
              </tr>
            ))}
        </S.TableBody>
      </S.Table>
    </LayoutTemplate>
  );
};
