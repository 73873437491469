import { NavLink } from "react-router-dom";
import * as S from "./styles";
import { useMenu } from "./useMenu";
import { DetailsItemsMenu } from "../../Atoms/DetailsItemsMenu";

export const Menu = () => {
  const { isOpen, setIsOpen, firstName, handleLogout } = useMenu();

  return (
    <S.MenuWrapper data-open={isOpen}>
      <S.MenuHeader data-open={isOpen}>
        <S.WrapperProfile data-open={isOpen}>
          <img src="/assets/svg/profile.svg" alt="icone profile" />
          <S.WrapperProfileText>
            <p>
              Olá, <span>{firstName}</span>
            </p>
            <button onClick={handleLogout}>
              logout <img src="/assets/svg/icon-exit.svg" alt="icon exit" />
            </button>
          </S.WrapperProfileText>
        </S.WrapperProfile>

        <img
          src="/assets/svg/icon-menu.svg"
          alt="icon menu"
          onClick={() => setIsOpen((prev) => !prev)}
        />
      </S.MenuHeader>

      <S.MenuItems>
        <S.MenuItem>
          <NavLink to={"/relatorio-geral"}>
            <img src="/assets/svg/icon-menu1.svg" alt="icone grafico" />
            Produção Geral
          </NavLink>
        </S.MenuItem>

        <DetailsItemsMenu
          titleHeader="Tendência"
          icon="/assets/svg/icon-menu2.svg"
        >
          <NavLink to={"/relatorio-tendencia/starcheck"}>Starcheck</NavLink>
          <NavLink to={"/relatorio-tendencia/log"}>Log Vistorias</NavLink>
          <NavLink to={"/relatorio-tendencia/tokyo"}>Tokyo Vistorias</NavLink>
          <NavLink to={"/relatorio-tendencia/vlx"}>VLX Vistorias</NavLink>
        </DetailsItemsMenu>

        <S.MenuItem>
          <NavLink to={"/relatorio-reembolso"}>
            <img src="/assets/svg/icon-menu3.svg" alt="icone grafico" />
            Reembolsos
          </NavLink>
        </S.MenuItem>

        <S.MenuItem>
          <NavLink to={"/relatorio-analitico-reembolso"}>
            <img src="/assets/svg/icon-menu4.svg" alt="icone reembolso" />
            Reembolso - Analítico
          </NavLink>
        </S.MenuItem>
        <S.MenuItem>
          <NavLink to={"/relatorio-colchao"}>
            <img src="/assets/svg/icon-menu5.svg" alt="icone grafico" />
            Colchão de Agendamentos
          </NavLink>
        </S.MenuItem>

        <S.MenuItem>
          <NavLink to={"/relatorio-produtividade-vistoriador"}>
            <img src="/assets/svg/icon-menu6.svg" alt="icone grafico" />
            Produtividade por Vistoriador
          </NavLink>
        </S.MenuItem>

        <S.MenuItem>
          <NavLink to={"/relatorio-produtividade-servico"}>
            <img src="/assets/svg/icon-menu7.svg" alt="icone engrenagem" />
            Produção por Tipo de Serviço
          </NavLink>
        </S.MenuItem>
      </S.MenuItems>
    </S.MenuWrapper>
  );
};
