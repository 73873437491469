import styled from "styled-components";

export const Wrapper = styled.details`
  &:has(a.active) {
    > summary {
      > div {
        > p {
          color: #fff;
        }

        > img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
            saturate(4368%) hue-rotate(323deg) brightness(117%) contrast(113%);
        }
      }
    }
  }
`;

export const Summary = styled.summary`
  all: unset;

  display: flex;
  justify-content: space-between;
  cursor: pointer;
  white-space: nowrap;

  > div {
    display: flex;
    gap: 16px;
    align-items: center;

    > p {
      color: #878787;
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
    }

    > img {
      display: block;
      width: 24px;
      height: 24px;
    }
  }

  > img {
    display: block;
  }
`;

export const Content = styled.div`
  padding: 32px 0 0;

  > a {
    width: fit-content;
    position: relative;
    margin-left: 10px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 28px;
    color: #878787;
    position: relative;

    font-family: "Noto Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 500;

    &::before {
      display: block;
      content: "";
      position: relative;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      z-index: 10;
      background-color: #878787;
    }
  }

  > a.active {
    color: #fff;

    &::before {
      background-color: #fff;
      width: 5px;
      height: 5px;
    }
  }

  > a + a {
    margin-top: 24px;
  }

  > a + a:not(:last-child) {
    margin-top: 24px;

    &::after {
      content: "";
      position: absolute;
      left: 2px;
      z-index: 0;
      width: 1px;
      height: 42px;
      background-color: #878787;
    }
  }

  > a:first-child {
    &::after {
      content: "";
      position: absolute;
      left: 2px;
      z-index: 0;
      top: 10px;
      width: 1px;
      height: 20px;
      background-color: #878787;
    }
  }

  > a:last-child {
    &::after {
      content: "";
      position: absolute;
      left: 2px;
      z-index: 0;
      top: -12px;
      width: 1px;
      height: 20px;
      background-color: #878787;
    }
  }
`;
