import { useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";

export const useLayout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return { navigate, NavLink };
};
