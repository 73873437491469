import { ComponentProps } from "react";
import * as S from "./styles";

interface IDetailsItemsMenuProps extends ComponentProps<"details"> {
  icon: string;
  titleHeader: string;
}

export const DetailsItemsMenu = (props: IDetailsItemsMenuProps) => {
  return (
    <S.Wrapper {...props}>
      <S.Summary>
        <div>
          <img src={props.icon} alt="icone menu" />
          <p>{props.titleHeader}</p>
        </div>
        <img src="/assets/svg/icon-setadown.svg" alt="seta" />
      </S.Summary>

      <S.Content>{props?.children}</S.Content>
    </S.Wrapper>
  );
};
